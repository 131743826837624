:root {
  //Light Theme
  @each $key, $color in $v2-light-theme-colors {
    --color-#{$key}: #{to-rgb($color)};
    --color-#{$key}-alpha: #{alpha($color)};
  }

  // Dark Theme
  &[data-theme='dark'] {
    @each $key, $color in $v2-dark-theme-colors {
      --color-#{$key}: #{to-rgb($color)};
      --color-#{$key}-alpha: #{alpha($color)};
    }
  }
}

html {
  scroll-behavior: smooth;

  * {
    // テーマ切り替え時の背景色をゆっくり切り替える
    transition: background-color .6s ease;
  }
}

body {
  background-color: $white;
  color: $black1;
}

p {
  font-size: .875rem;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

a {
  @include text-decoration-none;
  @include decrease-opacity($black1);
}

li {
  font-size: .875rem;

  @include media-breakpoint-up(lg) {
    font-size: 1rem;
  }
}

ul {
  list-style: disc;
}

// Bootstrap のコンテナ
.container {
  @include make-container();
  @include make-container-max-widths();
}

.img-fluid {
  width: 100%;
  height: auto;
}

// ============================================================
// next-page-transitions
// ============================================================
.page-transition-enter {
  opacity: 0;
}

.page-transition-enter-active {
  opacity: 1;
  transition: opacity .3s;
}

.page-transition-exit {
  opacity: 1;
}

.page-transition-exit-active {
  opacity: 0;
  transition: opacity .3s;
}
